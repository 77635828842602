import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { useScript } from 'hooks/useScript';

import { REVTRAX_HOST } from 'utils/constants';

import { getRevTraxScriptSrc } from './utils';

import './RevTraxCoupon.scss';

const RevTraxCoupon = ({
  affiliateId,
  channel,
  merchantId,
  programId,
  rxChannel,
  id,
  user,
  signUpMessage,
  logInMessage,
}: PageContent.RevTraxCouponType) => {
  const [isLoaded] = useScript(
    getRevTraxScriptSrc({
      affiliateId,
      channel,
      merchantId,
      programId,
      rxChannel,
      id,
    }),
    REVTRAX_HOST
  );

  useEffect(() => {
    if (isLoaded) {
      // eslint-disable-next-line no-new-func
      new Function(`makeFrame${id}()`)();
    }
  }, [isLoaded, id]);

  const isDisabled = !user?.isLoggedIn || !user?.isRegistered;
  const disabledMessage = user?.isRegistered ? logInMessage : signUpMessage;

  return (
    <section
      className={classNames('rev-trax-coupon', {
        'rev-trax-coupon--disabled': isDisabled,
      })}
      id={id}
      data-testid="rev-trax-coupon"
    >
      {isDisabled ? (
        <div className="rev-trax-coupon__message-overlay">
          <h2 className="rev-trax-coupon__disabled-message">{disabledMessage}</h2>
        </div>
      ) : null}
    </section>
  );
};

export default RevTraxCoupon;

export const RevTraxType = graphql`
  fragment RevTraxType on RevTraxType {
    signUpMessage
    logInMessage
  }
`;
