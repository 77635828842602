import React, { FC, memo, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';
import { logInStrings } from 'templates/Coupons/constants';

import CustomButton from 'common/CustomButton';
import runOnUserInteraction from 'utils/runOnUserInteraction';

import Form from '../Form';

const LogIn: FC<PageContent.LogInType> = ({ title, logInHandler }) => {
  const [shouldLoadGoogleReCaptchaProvider, setShouldLoadGoogleReCaptchaProvider] = useState(false);

  const handleSuccessfulSubmit = (value) => {
    if (value) {
      logInHandler(true, true);
    }
  };
  const {
    customSectionText,
    customSectionButtonText,
    validationMessage,
    emailExistsValidationMessage,
    customButton,
    inputs,
  } = logInStrings;

  const CustomSection = memo(() => (
    <p className="custom-section">
      {customSectionText}
      <CustomButton
        buttonLabel={customSectionButtonText}
        backgroundColor="link"
        onClick={() => logInHandler(false, false)}
        ariaLabel={customSectionButtonText}
      />
    </p>
  ));

  const LogInForm = memo(() => (
    <Form
      {...{
        validationMessage,
        emailExistsValidationMessage,
        customButton,
        inputList: [
          {
            ...inputs.email,
          },
        ],
        agreements: [
          {
            brand: 'Resolve',
            market: 'CA',
            agreements: [
              {
                ActivityDate: new Date().toISOString(),
                AgreementDate: new Date().toISOString(),
                BusinessId: process.env.GATSBY_CDP_BUSINESS_ID!,
                ConsentDesc: 'Resolve',
                RevisionId: process.env.GATSBY_CDP_REVISION_ID!,
                ConsentAcceptedInd: true,
                MandatoryInd: false,
              },
            ],
          },
        ],
        handleSuccessfulSubmit,
        CustomSection,
        formKeys: {
          programCode: process.env.GATSBY_CDP_PROGRAM_CODE,
          brandOrgCode: process.env.GATSBY_CDP_BRAND_ORG_CODE,
          accountSource: process.env.GATSBY_CDP_ACCOUNT_SOURCE_COUPON,
        } as FormContent.FormKeysType,
      }}
    />
  ));

  useEffect(() => {
    runOnUserInteraction(() => setShouldLoadGoogleReCaptchaProvider(true));
  }, []);

  return (
    <section className="coupons-form form-section">
      <div className="form-section__col">
        <div className="form-section__info-text">
          <h3 className="form-section__title">{title}</h3>
        </div>
      </div>
      <div className="form-section__col">
        {shouldLoadGoogleReCaptchaProvider ? (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <LogInForm />
          </GoogleReCaptchaProvider>
        ) : (
          <LogInForm />
        )}
      </div>
    </section>
  );
};

export default LogIn;

export const SignUpType = graphql`
  fragment LogInType on LogInType {
    title
  }
`;
