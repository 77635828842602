import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import CouponsList from 'components/CouponsList';
import LogIn from 'components/LogIn';
import RecommendedProducts from 'components/RecommendedProducts';
import RevTraxCoupon from 'components/RevTraxCoupon';
import SignUp from 'components/SignUp';
import SlimBanner from 'components/SlimBanner';
import { REVTRAX_CREDENTIALS } from 'utils/constants';
import { sortRecommendedProducts } from 'utils/productsSorting';
import { getStorageData, setStorageData } from 'utils/storage';

import './Coupons.scss';

const Coupons = ({
  data: {
    coupons,
    allProductDetails: { nodes: allRecommendedProductsNodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: CouponsTypes.CouponsProps) => {
  const [userData, setUserData] = useState<PageContent.UserType | null>();
  const signUpHandler = (isLoggedIn, isRegistered) => {
    setStorageData('user', { isLoggedIn, isRegistered });
    setUserData({ isLoggedIn, isRegistered });
  };

  const logInHandler = (isLoggedIn, isRegistered) => {
    setStorageData('user', { isLoggedIn, isRegistered });
    setUserData({ isLoggedIn, isRegistered });
  };

  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  useEffect(() => {
    setUserData(getStorageData('user'));
  }, []);

  return coupons ? (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo {...{ lang, urls: coupons.urls, seo: coupons.seo }} />
      <PageSchema
        type="WebPage"
        name={coupons.seo.title}
        data={{
          metaTitle: coupons.seo.title,
          metaDescription: coupons.seo.description,
          metaKeywords: coupons.seo.keywords,
        }}
      />
      <Banner {...coupons.heroBanner} />
      {coupons.coupons ? (
        <CouponsList
          mainTitle={coupons.coupons.mainTitle}
          couponsList={coupons.coupons.couponsList}
        />
      ) : null}
      {!userData?.isRegistered ? (
        <SignUp {...coupons.signUp} signUpHandler={signUpHandler} />
      ) : null}
      {!userData?.isLoggedIn && userData?.isRegistered ? (
        <LogIn {...coupons.logIn} logInHandler={logInHandler} />
      ) : null}
      {coupons.revTrax ? (
        <RevTraxCoupon {...{ ...REVTRAX_CREDENTIALS, ...coupons.revTrax, user: userData }} />
      ) : null}
      <RecommendedProducts
        {...{
          allRecommendedProductsNodes: sortRecommendedProducts(
            coupons.recommendedProducts.products,
            allRecommendedProductsNodes
          ),
          recommendedTitle: coupons.recommendedProducts.recommendedTitle,
        }}
      />
      <SlimBanner {...coupons.slimInfoBanner} />
    </Layout>
  ) : null;
};

export const query = graphql`
  query ($lang: String, $id: String, $recommendedProductsSku: [String]) {
    coupons(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      heroBanner {
        ...BannerType
      }
      slimInfoBanner {
        ...SlimBannerType
      }
      revTrax {
        ...RevTraxType
      }
      signUp {
        ...SignUpType
      }
      logIn {
        ...LogInType
      }
      coupons {
        ...CouponsListType
      }
      recommendedProducts {
        recommendedTitle
        products {
          value
        }
      }
    }
    allProductDetails(filter: { sku: { in: $recommendedProductsSku } }) {
      nodes {
        title
        url
        productImage {
          ...SalsifyImageType
        }
        sku
      }
    }
  }
`;

export default Coupons;
