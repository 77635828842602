import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import CustomLink from 'common/CustomLink';
import Image from 'common/Image';

import './CouponsList.scss';

const CouponsList: FC<PageContent.CouponsListType> = ({ mainTitle, couponsList }) => (
  <div className="coupons-list">
    {mainTitle ? <h2 className="coupons-list__title">{mainTitle}</h2> : null}

    {couponsList?.length
      ? couponsList.map(({ title, image, description, button }) => (
          <div key={title} className="coupons-list__item" data-testid="coupons-list">
            {title ? <h3 className="coupons-list__item-title">{title}</h3> : null}

            {description ? (
              <div className="coupons-list__item-description">
                <DangerouslySetInnerHtml html={description} element="div" />
              </div>
            ) : null}

            {image ? (
              <div className="coupons-list__item-image">
                <Image alt={image.altText} imageData={image} />
              </div>
            ) : null}

            {button ? (
              <CustomLink
                linkLabel={button[0].linkLabel}
                color={button[0].color}
                link={button[0].link}
                isLinkExternal={button[0].isLinkExternal}
                className="coupons-list__item-button"
              />
            ) : null}
          </div>
        ))
      : null}
  </div>
);

export const CouponsListType = graphql`
  fragment CouponsListType on CouponsListType {
    mainTitle
    couponsList {
      title
      description
      image {
        ...UmbracoImage
      }
      button {
        ...CustomLinkType
      }
    }
  }
`;

export default CouponsList;
